<template>
	<main>
		<div class="el-content">
			<div class="info">
				<div class="p">您好，<i>{{admin_info.nickname}}</i></div>
				<div class="p">现在是：{{time}}</div>
			</div>
		</div>
	</main>
</template>

<script >
import moment from "moment"
import ComponentsDistrict from "@/components/admin/form/District"
import {dashboard} from "@/api/admin";

export default {
	layout:'admin',
	components:{ComponentsDistrict},
	data(){
		return {
			timer:"",
			time:"",
		}
	},
	computed:{
		admin_info(){
			return this.$store.state.admin.admin_info
		}
	},
	mounted() {
		this.createTime();
	},
	methods:{
		createTime(){
			const step = ()=>{
				this.time = moment().format('YYYY年MM月DD日 HH:mm:ss')
			}
			step();

			clearInterval(this.timer);
			this.timer = setInterval(()=>{
				step()
			},1000);

			this.$once('beforeDestroy',()=>{
				clearInterval(this.timer);
			})
		}
	}
}
</script>

<style lang="scss" scoped>
.info{font-size: 16px;line-height: 30px;
	i{font-weight: bold;}
}
</style>
